import { AppAction, loadState } from '../actions'
import { Dispatch } from 'redux'

import { GlobalSettings } from '../types/global-settings'
import { CalibrationSettingsBase, CalibrationSettings1VP, CalibrationSettings2VP } from '../types/calibration-settings'
import { ControlPointsStateBase, ControlPointsState1VP, ControlPointsState2VP } from '../types/control-points-state'
import { CameraParameters } from '../solver/solver-result'
import { ResultDisplaySettings } from '../types/result-display-settings'
import { defaultResultDisplaySettings } from '../defaults/result-display-settings'
import { cameraPresets } from '../solver/camera-presets'
import { StoreState } from '../types/store-state'
import store from '../store/store'
import { post } from '../http'


export default interface SavedState {
    globalSettings: GlobalSettings

    calibrationSettingsBase: CalibrationSettingsBase
    calibrationSettings1VP: CalibrationSettings1VP
    calibrationSettings2VP: CalibrationSettings2VP

    controlPointsStateBase: ControlPointsStateBase
    controlPointsState1VP: ControlPointsState1VP
    controlPointsState2VP: ControlPointsState2VP

    cameraParameters: CameraParameters | null
    resultDisplaySettings: ResultDisplaySettings
}


export function loadImage(
    imageBuffer: Buffer,
    onLoad: (width: number, height: number, url: string) => void,
    onError: () => void
) {
    let blob = new Blob([imageBuffer])
    let url = URL.createObjectURL(blob)
    let image = new Image()
    image.src = url
    image.onload = (_: Event) => {
        onLoad(image.width, image.height, url)
    }
    image.onerror = (_) => {
        onError()
    }
}


export const load = (path: string, dispatch: Dispatch<AppAction>) => {
    return new Promise<void>((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", path, true);
        xhr.responseType = "arraybuffer";

        xhr.onload = function () {
            const enc = new TextDecoder("utf-8");
            const buffer = new Uint8Array(this.response)
            const dataView = new DataView(this.response);
            let headerSize = 16;
            let stateStringSize = dataView.getUint32(8, true);
            let stateStringBuffer = buffer.slice(headerSize, headerSize + stateStringSize);
            let stateString = enc.decode(stateStringBuffer);
            let imageBufferSize = dataView.getUint32(12, true);
            let imageBuffer: Buffer | null = null;
            if (imageBufferSize > 0) {
                imageBuffer = buffer.slice(headerSize + stateStringSize) as Buffer;
            }
            let loadedState: SavedState = JSON.parse(stateString)
            if (loadedState.cameraParameters === undefined) {
                loadedState.cameraParameters = null
            }
            if (loadedState.resultDisplaySettings === undefined) {
                loadedState.resultDisplaySettings = defaultResultDisplaySettings
            }

            const cameraPresetId = loadedState.calibrationSettingsBase.cameraData.presetId
            if (cameraPresetId) {
                if (cameraPresets[cameraPresetId] === undefined) {
                    loadedState.calibrationSettingsBase.cameraData.presetId = null
                }
            }

            if (loadedState.calibrationSettingsBase.cameraData.presetData === undefined) {
                loadedState.calibrationSettingsBase.cameraData.presetData = null
                const presetId = loadedState.calibrationSettingsBase.cameraData.presetId
                if (presetId) {
                    const preset = cameraPresets[presetId]
                    if (preset) {
                        loadedState.calibrationSettingsBase.cameraData.presetData = preset
                    }
                }
            }

            if (imageBuffer) {
                loadImage(
                    imageBuffer,
                    (width: number, height: number, url: string) => {
                        dispatch(
                            loadState(
                                loadedState,
                                {
                                    width: width,
                                    height: height,
                                    data: imageBuffer,
                                    url: url
                                },
                                path
                            )
                        )
                        resolve()
                    },
                    () => {
                        reject('Failed to load')
                    }
                )
            };
        }

        xhr.send();
    })
}

const getStateToSave = (): SavedState => {
    let storeState: StoreState = store.getState()
    return {
        globalSettings: storeState.globalSettings,
        calibrationSettingsBase: storeState.calibrationSettingsBase,
        calibrationSettings1VP: storeState.calibrationSettings1VP,
        calibrationSettings2VP: storeState.calibrationSettings2VP,
        controlPointsStateBase: storeState.controlPointsStateBase,
        controlPointsState1VP: storeState.controlPointsState1VP,
        controlPointsState2VP: storeState.controlPointsState2VP,
        cameraParameters: storeState.solverResult.cameraParameters,
        resultDisplaySettings: storeState.resultDisplaySettings
    }
}

export const save = (code: string) => {
    const elems = document.getElementsByClassName("center-wrapper") as HTMLCollectionOf<HTMLDivElement>;
    for (let i = 0; i < elems.length; i++) {
        elems[i].style.display = "flex";
    }
    const path: string = `${code}.fspy`;
    const storeState: StoreState = store.getState()

    const imageData = storeState.image.data as Uint8Array
    const stateToSave = getStateToSave()

    const stateJsonString = JSON.stringify(stateToSave)
    const stateBuffer = new ArrayBuffer(stateJsonString.length)
    const stateDataView = new DataView(stateBuffer);
    for (let i = 0, strLen = stateJsonString.length; i < strLen; i++) {
        stateDataView.setUint8(i, stateJsonString.charCodeAt(i))
    }

    const buffer = new ArrayBuffer(16)
    const dataView = new DataView(buffer);

    dataView.setUint8(0, 'fspy'.charCodeAt(0))
    dataView.setUint8(1, 'fspy'.charCodeAt(1))
    dataView.setUint8(2, 'fspy'.charCodeAt(2))
    dataView.setUint8(3, 'fspy'.charCodeAt(3))
    dataView.setUint32(4, 1, true)
    dataView.setUint32(8, stateBuffer.byteLength, true)
    dataView.setUint32(12, imageData ? imageData.length : 0, true)

    const blob = new Blob([dataView, stateDataView, imageData], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob)
    const a = document.getElementById('download-link') as HTMLAnchorElement;
    document.body.appendChild(a);
    a.href = url;
    a.download = path;

    const formData = new FormData();
    formData.append("fspy", blob, path);

    let server =
        (
            window.location.protocol === "https:" &&
            window.location.href.indexOf("dev") === -1
        ) ?
            "https://server.vs24.com" : "http://server.vs24develop.com";
    return post(`${server}/api/taylor/${code}/fspy`, {}, formData);
}

export const openTaylor3d = (code: string) => {
    let server =
        (
            window.location.protocol === "https:" &&
            window.location.href.indexOf("dev") === -1
        ) ?
            "https://t3d.photobon.realestate/" : "http://dev.t3d.link/";

    const mapForm = document.createElement("form");
    const codeInput = document.createElement("input") as HTMLInputElement;;
    const authInput = document.createElement("input") as HTMLInputElement;

    mapForm.method = "GET";
    mapForm.action = `${server}index.html`;
    codeInput.type = "text";
    codeInput.name = "code";
    codeInput.value = code;
    authInput.type = "text";
    authInput.name = "auth";
    authInput.value = localStorage.getItem("authToken") || '';
    mapForm.appendChild(codeInput);
    mapForm.appendChild(authInput);
    document.body.appendChild(mapForm);
    mapForm.submit();
}
