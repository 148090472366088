import React from "react";
import ReactDOM from "react-dom/client";
import App from './App'
import store from './store/store'
import { Provider } from 'react-redux'
import './index.css'

const elem = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(elem);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);