const fetchThread = (url: string, options = {}, retries = 2, json = true): any =>
    fetch(url, options)
        .then(res => {
            if (res.ok) {
                if (json) {
                    return res.json();
                }
                return res;
            } else if (res.status >= 500 && retries > 0) {
                return fetchPlus(url, options, retries - 1, json);
            }
            throw new Error(res.status.toString());
        });

const fetchPlus = (url: string, options = {}, retries = 2, json = true) => fetchThread(url, options, retries, json);

export function post(url: string, headers: any, body?: FormData): Promise<Response> {
    const authToken = localStorage.getItem("authToken");
    const requestObject: any = {
        method: "POST",
        headers: { ...headers, Authorization: authToken },
    }
    if (body) {
        requestObject['body'] = body;
    }
    return fetchPlus(
        url,
        requestObject,
        2,
        false
    );
}